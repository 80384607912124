<template>
  <v-container class="email-report-view mt-5 px-10" fluid>
    <PageHeader
      header-text="Email Report"
      class="mb-7"
    >
      <template #subheader>
        <div class="d-flex">
          <div class="flex-fill">
            Generate your email report
          </div>
          <CustomDateRangePicker
            v-if="campaigns.length > 0"
            v-model="dateRanges"
            :format="showDateFormat"
            :max-date="new Date()"
          />
        </div>
      </template>
    </PageHeader>

    <template v-if="campaigns.length > 0">
      <v-row class="mb-3">
        <v-col cols="12" sm="6" md="6">
          <div class="field__header">
            Email Sends
          </div>
          <div class="d-flex align-center mt-5">
            <div
              v-if="selectedEmailSends.length === 0"
              class="field__description mr-3"
            >
              Select 1 or more email sends
            </div>
            <CreativesModal
              v-if="campaigns"
              :campaigns="campaigns"
              :max-selections="maxSelections"
              @selected-campaign="updateSelectedCampaign"
            />
          </div>
          <div />
        </v-col>

        <v-col cols="12" sm="4" md="4">
          <CustomDropdown
            v-if="selectedEmailSends.length <= 3"
            v-model="segmentBreakdown"
            :items="segments"
            header="Segments"
            header-class="field__header"
            item-value="id"
            item-text="name"
            multiple
            short
            autocomplete
            hint="View email send metrics for contacts in the segments. A max of 5 segments can be selected at a time. Additionally, this feature can only be used with a max of up to 3 email sends."
          />
          <div v-else>
            <div class="field__header mb-3">
              Segments
            </div>
            <div style="font-size: 14px;">
              This feature is only allowed for up to 3 email sends. Please decrease the amount of selected email sends to use this feature.
            </div>
          </div>
        </v-col>
      </v-row>

      <div class="field__header mb-5">
        {{ `Selected (${selectedEmailSends.length})` }}
      </div>
      <v-row>
        <v-col
          v-for="(item, i) in selectedEmailSends"
          :key="i"
          cols="12"
          sm="6"
          md="4"
          class="email-send-container"
        >
          <div class="segment-block d-flex align-center justify-space-between pl-3 py-2 pr-1">
            <div class="mr-3">
              <div class="d-block">
                <div>
                  <strong>Action: </strong>
                  {{ item.actionNodeLabel }}
                </div>
                <div v-if="item.test">
                  <strong>Test: </strong>
                  {{ item.test }}
                </div>
                <div>
                  <strong>Creative: </strong>
                  # {{ item.emailMessage.id }} - {{ item.emailMessage.name }}
                </div>
                <div>
                  <strong>Subject: </strong>
                  {{ item.subject }}
                </div>
                <div v-if="item.previewText">
                  <strong>Preview Text: </strong>
                  {{ item.previewText }}
                </div>
              </div>
            </div>
            <div>
              <v-btn icon>
                <v-icon color="#66788e" size="16" @click="deleteReport(item.id)">
                  mdi-close
                </v-icon>
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
      <div class="d-flex justify-end my-5">
        <v-btn
          class="custom-button custom-button--gray mb-4"
          height="34px"
          depressed
          width="150px"
          :block="$vuetify.breakpoint.xsOnly"
          @click="resetInputs"
        >
          Reset
        </v-btn>
        <v-btn
          class="custom-button custom-button--blue ml-sm-5 mb-4"
          height="34px"
          width="150px"
          depressed
          :block="$vuetify.breakpoint.xsOnly"
          @click="generateReport"
        >
          Generate Report
        </v-btn>
        <v-btn
          class="custom-button custom-button--blue ml-sm-5 mb-4"
          height="34px"
          width="150px"
          depressed
          @click="generatePdfReport"
        >
          Generate PDF Report
        </v-btn>
      </div>

      <v-container
        v-if="reports.length > 0" 
        class="px-0"
        fluid
      >
        <v-divider class="mb-6" />

        <v-row>
          <v-col cols="8">
            <AIPromptDisplay
              :is-loading="emailSummaryReportLoading"
              :response="emailSummaryReport ?? {}"
              :response-type="emailSummaryResponseType"
              header="Email Report Summary"
              is-beta
            />
          </v-col>
          <v-col cols="4">
            <div class="text-right">
              Generated Report {{ getDateWithTime(reportRequestDate) }}
            </div>
          </v-col>
        </v-row>
        <Report
          v-if="isShowReport"
          :reports="reports"
          :segment-reports="segmentReports"
          class="mt-5"
        />

        <PdfReport
          v-if="!isShowReport"
          :reports="reports"
          :segment-reports="segmentReports"
          :request-date="reportRequestDate"
          :pdf-settings="pdfSettings"
          class="mt-5 print-report"
        />
      </v-container>
    </template>
    <template v-else>
      <v-row>
        <v-col cols="12" class="text-center">
          There is nothing to generate a report for yet!
        </v-col>
      </v-row>
    </template>
    <v-dialog
      v-model="isShowModal"
      max-width="360px"
    >
      <v-card class="custom-field-popup-card" style="padding: 15px">
        <validation-observer ref="textInput">
          <CustomTextInput
            header="PDF Report Name"
            :value="pdfSettings.pdfReportName"
            class="flex-grow-1"
            placeholder="PDF Report Name"
            :maxlength="150"
            :required="true"
            @input="pdfSettings.pdfReportName = $event"
          />
          <v-checkbox
            v-model="pdfSettings.isShowEmailImage"
            label="Email Message Preview"
            class="custom-fields-row-checkbox mt-1 mr-1"
          />
          <v-checkbox
            v-model="pdfSettings.isShowEmailMetrics"
            label="Email Metrics"
            class="custom-fields-row-checkbox mt-1 mr-1"
          />
          <v-checkbox
            v-model="pdfSettings.isShowKPI"
            label="KPI Performance Bar Charts"
            class="custom-fields-row-checkbox mt-1 mr-1"
          />
          <v-checkbox
            v-model="pdfSettings.isShowHeatmap"
            label="Open Rate Heatmap"
            class="custom-fields-row-checkbox mt-1 mr-1"
          />
          <v-checkbox
            v-model="pdfSettings.isShowTopLinks"
            label="Top Links"
            class="custom-fields-row-checkbox mt-1 mr-1"
          />
          <v-btn
            class="custom-button custom-button--blue px-15"
            height="34px"
            depressed
            @click="onSave"
          >
            Save
          </v-btn>
        </validation-observer>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import PageHeader from "@/sharedComponents/PageHeader";
import CreativesModal from "@/views/Reports/Email/components/CreativesModal";
import datesMixin from "@/utils/datesMixin.js";
import dayjs from "dayjs";
import CustomDateRangePicker from "@/sharedComponents/CustomDateRangePicker";
import CustomDropdown from "@/sharedComponents/CustomDropdown";
import Report from "@/views/Reports/Email/components/Report";
import PdfReport from "@/views/Reports/Email/components/PdfReport";
import CustomTextInput from "@/sharedComponents/CustomTextInput";
import AIPromptDisplay from "../../AI/components/AIPromptDisplay.vue";

const showDateFormat = 'MM/DD/YYYY'
const systemDateFormat = "YYYY-MM-DD";

export default {
  name: "EmailReport",
  metaInfo: {
    title: 'Email Report'
  },
  components: {
    AIPromptDisplay,
    CustomDateRangePicker,
    PageHeader,
    CreativesModal,
    Report,
    PdfReport,
    CustomTextInput,
    CustomDropdown,
  },
  mixins: [datesMixin],
  props: {
    selectedCampaignIDs: {
      type: Array,
      default: () => [],
    },
    selectedEmailSendIDs: {
      type: Array,
      default: () => [],
    },
    selectedSegmentIDs: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      emailId: null,
      startDate: null,
      endDate: null,
      campaigns: [],
      allSegments: [],
      segmentBreakdown: [],
      reportRequestDate: '',
      reports: [],
      segmentReports: {},
      showDateFormat,
      dateRanges: null,
      groupLabels: ['A', 'B', 'C', 'D', 'E', 'F', 'G'],
      colorPattern: ['#2b84eb', '#54a8af', '#6a7ee0', '#da762a', '#dd6060', '#8dbf42', '#1a476f'],
      isShowReport: true,
      pdfSettings: {
        pdfReportName: '',
        isShowEmailImage: true,
        isShowEmailMetrics: true,
        isShowKPI: true,
        isShowHeatmap: true,
        isShowTopLinks: true,
      },
      isShowModal: false,
      emailSummaryReportLoading: false,
      emailSummaryReport: null,
      emailSummaryResponseType: null,
    }
  },
  computed: {
    maxSelections() {
      if (this.segmentBreakdown.length > 0) {
        return 3;
      }

      return 5;
    },
    segments() {
      return this.allSegments.map(segment => ({
        ...segment,
        disabled: this.segmentBreakdown.length >= 5 && !this.segmentBreakdown.includes(segment.id)
      }));
    },
    selectedEmailSends: {
      get() {
        const selected = [];

        this.campaigns.forEach((c) => {
          if (!c.subjects) {
            return;
          }

          const isSelected = this.selectedCampaignIDs.includes(c.campaign.id);
          c.subjects.forEach((s) => {
            if (isSelected || s.selected) {
              selected.push(s);
            }
          })
        });

        return selected;
      },
    },
    selectedSegments: {
      get() {
        return this.segmentBreakdown;
      },
    },
  },
  watch: {
    selectedEmailSends: {
      deep: true,
      immediate: true,
      handler(newVal, oldVal) {
        // Use json stringify to compare array
        if(JSON.stringify(newVal) != JSON.stringify((oldVal ?? []))) {
          this.campaigns.forEach((c) => {
            if (!c.subjects) {
              return;
            }

            const hasNoEmailSendSelected = c.subjects.every(s => !s.selected)
            const hasAllEmailSendSelected = c.subjects.every(s => s.selected)
            if (hasNoEmailSendSelected) {
              c.selected = false;
            } else if (hasAllEmailSendSelected) {
              c.selected = true;
            }
          });

          const newSelectedEmailSendIDs = newVal.map(val => val.id);
          if (JSON.stringify(newSelectedEmailSendIDs) == JSON.stringify((this.selectedEmailSendIDs))) {
            return;
          }

          const query = { selectedEmailSendIDs: newSelectedEmailSendIDs };
          if (this.selectedSegmentIDs.length > 0) {
            query.selectedSegmentIDs = this.selectedSegmentIDs;
          }

          this.$router.push({
            name: this.$route.name,
            query,
          });
        }
      },
    },
    selectedSegments: {
      deep: true,
      immediate: true,
      handler(newVal, oldVal) {
        // Use json stringify to compare array
        if (JSON.stringify(newVal) != JSON.stringify((oldVal ?? []))) {
          const query = { selectedSegmentIDs: this.segmentBreakdown };
          if (this.selectedEmailSendIDs.length > 0) {
            query.selectedEmailSendIDs = this.selectedEmailSendIDs;
          }

          this.$router.push({
            name: this.$route.name,
            query,
          });
        }
      },
    }
  },
  async created() {
    await this.getCampaignSubjects();
    await this.getSegments();

    if (this.selectedSegmentIDs && this.selectedSegmentIDs.length > 0) {
      this.segmentBreakdown = this.selectedSegmentIDs;
    }
  },
  methods: {
    async loadAIEmailSummary() {
      if (this.reports.length === 0) {
        return null;
      }

      const statistics = this.reports.map(send => {
        return {
          campaignName: send.campaignName,
          subject: send.subject,
          emailType: send.configuration?.nodeType,
          delivered: send.delivered,
          uniqueOpens: send.uniqueOpens,
          uniqueClicks: send.uniqueClicks,
          uniqueOpenRate: send.delivered === 0 ? 0 : ((send.uniqueOpens / send.delivered) * 100).toFixed(2),
          uniqueClickRate: send.delivered === 0 ? 0 : ((send.uniqueClicks / send.delivered) * 100).toFixed(2),
          softBounces: send.softBounces,
          hardBounces: send.hardBounces,
          unsubscribes: send.unsubscribes,
          spamComplaints: send.spamComplaints,
          fromLine: send.fromLine.fromEmail,
          imageFileUrl: send.imageFileUrls?.full,
          linksClicked: send.linksClicked?.map(l => ({url: l.url, clicks: l.clicks})),
        }
      });

      try {
      this.emailSummaryReportLoading = true;

        const resp = await this.$rest.ai.generatePromptResponse(
          'emailReportSummary',
          {
            inputValues: {
              statistics: JSON.stringify(statistics)
            },
          },
          {
            hide_loader: true,
            hide_error: true,
          }
        );

        this.emailSummaryResponseType = resp.data.prompt?.promptOptions?.responseType ?? 'text';
        this.emailSummaryReport = resp.data;
        this.emailSummaryReportLoading = false;
      } catch (e) {
        this.emailSummaryResponseType = 'error';
        this.emailSummaryReport = {response: 'An unexpected error occurred.'};
        this.emailSummaryReportLoading = false;
      }
    },
    async onSave() {
      if (await this.validate() || this.pdfSettings.pdfReportName) {
        this.isShowModal = false;
        this.isShowReport = false;
        await this.processReport();
        setTimeout(() => {
          window.print();
          this.isShowReport = true;
        }, 500);
      }
    },
    async validate() {
      return await this.$refs.textInput.validate();
    },
    updateSelectedCampaign(campaignId) {
      const campaign = this.campaigns.find(c => c.campaign.id === campaignId);
      if (!campaign) {
        return;
      }

      const isSelected = campaign.selected;
      campaign.subjects = campaign.subjects.map(s => ({...s, selected: isSelected}));
    },
    deleteReport(emailSendId) {
      this.campaigns.forEach((c) => {
        if (!c.subjects) {
          return;
        }

        const emailSend = c.subjects.find(s => s.id === emailSendId);
        if (emailSend) {
          emailSend.selected = false;
        }

        const hasNoEmailSendSelected = c.subjects.every(s => !this.selectedEmailSends.includes(s.id))
        if (hasNoEmailSendSelected) {
          c.selected = false;
        }
      });
    },
    resetInputs() {
      this.emailId = null;
      this.startDate = null;
      this.endDate = null;

      this.campaigns = this.campaigns.map((c) => {
        if (c.subjects) {
          c.subjects = c.subjects.map((s) => ({...s, selected: false}));
        }

        return {...c, selected: false};
      });
    },
    generatePdfReport () {
      this.isShowModal = true;
    },

    async generateReport () {
      this.isShowReport = true
      await this.processReport();
      await this.loadAIEmailSummary();
    },
    async processReport() {
      if (this.selectedEmailSends.length === 0) {
        this.$store.commit('snackbar/showMessage', {
          content: 'Please select at least one email send',
          color: 'error',
        });

        return;
      }

      const reportData = {
        subjects: this.selectedEmailSends.map((item) => item.id)
      };
      if (this.dateRanges?.start && this.dateRanges?.end) {
        reportData.startDate = dayjs(this.dateRanges.start).format(systemDateFormat);
        reportData.endDate = dayjs(this.dateRanges.end).format(systemDateFormat);
      }
      if (this.segmentBreakdown.length > 0) {
        reportData.segments = this.segmentBreakdown.slice(0, 5);
      }

      const response = await this.$rest.report.get_email_report(reportData);
      if (!response.data?.report) {
        return;
      }

      this.reportRequestDate = this.getDateWithDayNameAndTimeInUserTimeZone(response.data.reportRequestDate);

      this.selectedEmailSends.forEach(s => {
        let subjectId = s.id;
        const emailSendReport = response.data.report.all[subjectId];

        response.data.report.all[subjectId] = {
          ...emailSendReport,
          subjectId: '' + subjectId,
          delivered: emailSendReport.delivered ?? '-',
          sends: emailSendReport.sends ?? '-',
          opens: emailSendReport.opens ?? '-',
          uniqueOpens: emailSendReport.uniqueOpens ?? '-',
          clicks: emailSendReport.clicks ?? '-',
          uniqueClicks: emailSendReport.uniqueClicks ?? '-',
          hardBounces: emailSendReport.hardBounces ?? '-',
          softBounces: emailSendReport.softBounces ?? '-',
          unsubscribes: emailSendReport.unsubscribes ?? '-',
          spamComplaints: emailSendReport.spamComplaints ?? '-',
          firstSendDate: emailSendReport.firstSendDate ?? '-',
        };
      });

      this.reports = Object.values(response.data.report.all)
          .sort((a, b) => {
            if (a.firstSendDate === '-') return 1;
            if (b.firstSendDate === '-') return -1;
            return new Date(a.firstSendDate) - new Date(b.firstSendDate);
          })
          .map((value, i) => ({
            ...value,
            groupLabel: this.selectedEmailSends.length > 1 ? this.groupLabels[i] : '',
            color: this.colorPattern[i],
          }));

      if (this.segmentBreakdown.length > 0) {
        this.segmentReports = response.data.report.segments;
      }
    },
    async getCampaignSubjects() {
      const response = await this.$rest.campaign.getEmailSubjects({
        ignorePagination: true,
        isArchived: false,
        onlyIncludeSent: true,
      });

      if (!response.data) {
        return;
      }

      const emailSubjects = response.data.items;
      const campaigns = {};

      emailSubjects.forEach(subject => {
        if (!subject?.campaignNode?.campaign) {
          return;
        }

        const campaign = subject?.campaignNode?.campaign;

        if (!campaign?.id) {
          return;
        }

        let existingCampaign = campaigns[campaign.id] ?? null;
        if (!existingCampaign) {
          existingCampaign = {
            campaign,
            createdAt: campaign.createdAt,
            subjects: [],
            selected: this.selectedCampaignIDs.includes(campaign.id),
          };

          campaigns[campaign.id] = existingCampaign;
        }

        let actionNodeLabel = 'Send Email';

        const nodeType = subject?.campaignNode?.configuration?.nodeType;
        if (nodeType) {
          if (nodeType === 'SendSplitTestEmail') {
            actionNodeLabel = 'Send Split Test Email';
          } else if (nodeType === 'AutoResendEmail') {
            actionNodeLabel = 'Auto Resend Email';
          }
        }
        existingCampaign.subjects.push({
          ...subject,
          actionNodeLabel,
          selected: this.selectedCampaignIDs.includes(campaign.id)
            || this.selectedEmailSendIDs.includes(subject.id),
        });
      });

      this.campaigns = Object.values(campaigns).sort((a,b) => {
        const aDate = Date.parse(a.createdAt ?? 0);
        const bDate = Date.parse(b.createdAt ?? 0);
        return bDate - aDate;
      });
    },
    async getSegments() {
      const resp = await this.$rest.segment.get_collection({
        ignorePagination: true,
        isArchived: false,
        sort: ['name:asc'],
      });

      this.allSegments = resp.data.items.map(segment => ({
        id: segment.id,
        name: segment.id + ' - ' + segment.name,
      }));
    },
  },
};
</script>

<style lang="scss" scoped>
.email-report-view {
  .report-background {
    background: #ecf1f5;
    width: 100%;

    .report-content {
      background: white;
    }
  }
}

.field {
  &__description {
    font-family: "Open Sans", sans-serif;
    font-size: 0.85rem;
    font-weight: normal;
    line-height: normal;
    color: #66788e;
  }
}

.segment-block {
  border-radius: 4px;
  border: solid 1px #dee7ee;
  font-family: "Open Sans", sans-serif;
  font-size: 0.85rem;
}
@media print {
  body * {
    visibility: hidden;
  }
  .pulse-navigation {
    display: none !important;
  }
  main {
    padding: 0;
  }
  .print-report, .print-report * {
    visibility: visible;
  }
  .noprint {
    visibility: hidden !important;
  }
  .print-report {
    position: absolute;
    left: 0;
    top: 0;
  }
  .pagebreak { page-break-before: always; } /* page-break-after works, as well */
  .pagebreak {
    clear: both;
    page-break-after: always;
  }
}
</style>
