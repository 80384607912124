<template>
  <v-card class="popup-card modal-overlay">
    <v-card-title class="px-6 pt-4 pb-4">
      <div>
        Link HeatMap
      </div>
    </v-card-title>
    <v-divider v-if="$vuetify.breakpoint.xsOnly" />
    <v-card-text class="px-6 pt-5">
      <div class="heatmap-container">
        <div v-html="html" />
        <canvas ref="heatmapCanvas" class="heatmap-canvas" />
      </div>
    </v-card-text>
    <v-card-actions
      class="divider-with-shadow d-flex justify-end px-6 pb-3 pt-3"
    >
      <v-btn
        height="34px"
        class="elevation-0 custom-button custom-button--blue"
        width="150px"
        @click="$emit('dismiss')"
      >
        Dismiss
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  name: "LinkHeatMap",
  components: {},
  props: {
    linksData: {
      default: () => [],
    },
    html: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      countNumbers: {},
      clickData: [],
      tooltipVisible: true,
      tooltipX: 0,
      tooltipY: 0,
      tooltipClicks: 0,
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeCanvas);
  },
  async mounted() {
    try {
      const response = await this.$rest.creative.email
          .render_html({ htmlContent: this.html })
      this.htmlRender = response?.data?.html;
      if (this.htmlRender) {
        this.$nextTick(() => {
          setTimeout(() => {
            this.renderHeatmap();
          }, 200);
        });
      }
      this.resizeCanvas();
      window.addEventListener('resize', this.resizeCanvas);
    } catch (error) {
      this.$store.commit("snackbar/showMessage", {
        content: "An unexpected error occurred. We apologize for the inconvenience.",
        color: "error"
      })
    }
  },
  methods: {
    resizeCanvas() {
      const canvas = this.$refs.heatmapCanvas;
      const container = canvas.parentElement;

      canvas.width = container.clientWidth;
      canvas.height = container.clientHeight;
    },
    renderHeatmap() {
      const canvas = this.$refs.heatmapCanvas;
      const context = canvas.getContext('2d');

      this.linksData.forEach(link => {
        const linkElement = this.$el.querySelector(`a[href="${link.url}"]`);
        if (linkElement) {
          const rect = linkElement.getBoundingClientRect();
          const canvasRect = canvas.getBoundingClientRect();
          const offsetX = rect.left - canvasRect.left;
          const offsetY = rect.top - canvasRect.top;
          const maxClicks = Math.max(...this.linksData.map(link => link.clicks));
          const intensity = link.clicks;
          const alpha = Math.min(intensity / maxClicks, 1) * 0.7;

          context.fillStyle = `rgba(255, 0, 0,  ${alpha})`;
          context.fillRect(offsetX, offsetY, rect.width, rect.height);
        }
      });
    }
  }
};
</script>
<style>
.heatmap-container {
  position: relative;
}

.links {
  position: relative;
  z-index: 1;
  display: inline-block;
  padding: 10px;
}

.heatmap-canvas {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 0;
  width: 100%;
  height: 100%;
  display: block;
}
</style>