<template>
  <div>
    <div
      v-if="header"
      :class="{'ai-prompt-display-header': true, 'loading': isLoading}"
    >
      <v-icon class="ml-1 mr-2" title="Pulse AI">
        $pulseAI
      </v-icon>
      {{ header }}
      <v-chip
        v-if="isBeta"
        color="#2b84eb"
        style="color: #FFFFFF;font-size: 10px; height: 25px;display: inline-block;"
      >
        BETA
      </v-chip>
    </div>
    <div v-if="isLoading">
      <skeleton-loader-vue
        :width="loadingWidth"
        :height="50"
        animation="wave"
        rounded
        color="#F8ECF4"
        :radius="4"
      />
    </div>
    <div
      v-else-if="response.response || responseType === 'json'"
      :class="{'ai-prompt-display': true, 'has-header': !!header}"
    >
      <template v-if="responseType === 'text'">
        <vue-typed-js
          :strings="[response.response]"
          :type-speed="5"
          :show-cursor="false"
        >
          <span class="typing" />
        </vue-typed-js>
      </template>
      <template v-else-if="responseType === 'list'">
        <vue-typed-js
          :strings="listItemResponse"
          :content-type="'html'"
          :smart-backpspace="true"
          :type-speed="5"
          :show-cursor="false"
        >
          <ul class="typing" />
        </vue-typed-js>
      </template>
      <template v-else-if="responseType === 'json'">
        <pre>{{ response }}</pre>
      </template>
      <template v-else-if="responseType === 'error'">
        <div style="font-size: 13px; color: red; font-weight: bold;">
          {{ response.response }}
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "AIPromptDisplay",
  props:{
    isLoading: {
      default: false,
      type: Boolean,
    },
    loadingWidth: {
      default: 600,
      type: Number,
    },
    response: {
      default: () => ({
        response: "",
      }),
      type: Object,
    },
    responseType: {
      default: "text",
      type: String,
    },
    header: {
      default: "",
      type: String,
    },
    isBeta: {
      default: false,
      type: Boolean,
    }
  },
  computed: {
    listItemResponse() {
      if (this.responseType !== 'list') {
        return [];
      }

      return [this.response.response.map(i => `<li>${i}</li>`).join('')];
    },
  },
}
</script>

<style lang="scss" scoped>
.ai-prompt-display-header {
  background: #C04A9A;
  color: #FFFFFF;
  font-weight: bold;
  padding: 5px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  &.loading {
    width: 600px;
  }
}
.ai-prompt-display {
  background: #F8ECF4;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #C04A9A;
  color: #000;
  font-size: 12px;

  &.has-header {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  pre {
    white-space: pre-wrap;
  }
}
</style>

